<template>
    <div>
    </div>
</template>
<script>
export default{
    beforeMount(){
        this.$router.push('/admin')
    }
}
</script>